export default [
  // {
  //   title: "Dashboard",
  //   route: "dashboard",
  //   icon: "ArchiveIcon",
  // },
  // {
  //   title: "Home",
  //   route: "home",
  //   icon: "HomeIcon",
  // },
  // {
  //   header: "Course",
  //   icon: "BookOpenIcon",
  //   children: [
  //     {
  //       title: "Department",
  //       route: "course-department",
  //     },
  //     {
  //       title: "Course List",
  //       route: "course",
  //     },
  //     {
  //       title: "Add Course",
  //       route: "course-create",
  //     },
  //     {
  //       title: "Option",
  //       route: "options",
  //     },
  //   ],
  // },
  // {
  //   header: "Success Story",
  //   icon: "CheckCircleIcon",
  //   children: [
  //     {
  //       title: "Success Story",
  //       route: "success_story",
  //     },
  //     {
  //       title: "Category",
  //       route: "success_story_category",
  //     },
  //   ],
  // },
  // {
  //   title: "CIT Achievement",
  //   route: "achievement",
  //   icon: "AwardIcon",
  // },
  // {
  //   header: "Student Review",
  //   icon: "HeadphonesIcon",
  //   children: [
  //     {
  //       title: "Category",
  //       route: "review_category",
  //     },
  //   ],
  // },
];
